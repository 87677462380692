export default [
  {location: {row:1, col:2}, values: ['5q', '1q', null]},
  {location: {row:1, col:3}, values: [null, '1a', '2q']},
  {location: {row:1, col:4}, values: ['6q', '3q', '2a']},
  {location: {row:1, col:5}, values: [null, '3a', '4q']},
  {location: {row:1, col:6}, values: ['7q', null, '4a']},
  {location: {row:2, col:2}, values: ['5a', null, '8q']},
  {location: {row:2, col:3}, values: [null, '9q', '8a']},
  {location: {row:2, col:4}, values: ['6a', '9a', '10q']},
  {location: {row:2, col:5}, values: [null, '11q', '10a']},
  {location: {row:2, col:6}, values: ['7a', '11a', null]},
]